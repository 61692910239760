import { NetspendFinalImportMap } from 'tools/importmap-loader/types';

const normalizePackages = <T>(
  packages: Record<string, T>,
  transformKey: (key: string) => string,
  transformValue: (val: T) => T
): Record<string, T> =>
  Object.entries(packages).reduce((acc, [k, v]) => {
    acc[transformKey(k)] = transformValue(v);
    return acc;
  }, {} as Record<string, T>);

const identity = <T>(v: T) => v;

export const normalizeImportMap = (
  { imports, scopes, depcache, integrity }: NetspendFinalImportMap,
  baseUrl: URL
): NetspendFinalImportMap => {
  const resolve = (url: string) => new URL(url, baseUrl).href;
  return {
    imports: normalizePackages(imports, identity, resolve),
    scopes: normalizePackages(scopes, resolve, (scopedItems) =>
      normalizePackages(scopedItems, resolve, resolve)
    ),
    depcache: normalizePackages(depcache, resolve, (deps) => deps.map(resolve)),
    integrity: normalizePackages(integrity, resolve, identity)
  };
};
