import { NetspendFinalImportMap } from 'tools/importmap-loader/types';
import { normalizeImportMap } from 'tools/importmap-loader/normalize';
import { systemJSPrototype } from './systemjs';

function resolveImportMap() {
  return new Promise((resolve) => {
    const script = document.querySelector<HTMLScriptElement>(
      'script[type="netspend-importmap"]'
    );
    if (!script) {
      return resolve(null);
    }

    const maybeRelativeBaseUrl = script.dataset.baseUrl || '';
    const baseUrl = new URL(maybeRelativeBaseUrl, document.baseURI);

    const injectImportMap = (importMap: NetspendFinalImportMap) => {
      const normalizedImportMap = normalizeImportMap(importMap, baseUrl);
      const systemImportMapScript = document.createElement('script');
      systemImportMapScript.type = 'systemjs-importmap';
      systemImportMapScript.innerHTML = JSON.stringify(normalizedImportMap);
      systemImportMapScript.dataset.netspendResolved = 'true';
      if (script.parentNode) {
        script.parentNode.replaceChild(systemImportMapScript, script);
      }
      resolve();
    };

    if (script.src) {
      return fetch(script.src)
        .then((resp) => resp.json())
        .then(injectImportMap);
    }

    injectImportMap(JSON.parse(script.innerHTML));
  });
}

// We have to start resolving immediately.
const importMapPromise = resolveImportMap();
const systemPrepareImport = systemJSPrototype.prepareImport;
systemJSPrototype.prepareImport = function () {
  return importMapPromise.then(() => systemPrepareImport.call(this));
};

// Must rename all the scripts. Otherwise,
// System will attempt to start loading them before we can finish
// decorating and it will bypass our hooks for the first imported
// modules
Array.from(
  document.querySelectorAll<HTMLScriptElement>('script[type="netspend-module"]')
).forEach((s) => (s.type = 'systemjs-module'));
