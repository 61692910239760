import { systemJSPrototype } from './systemjs';

const createScript = systemJSPrototype.createScript;
systemJSPrototype.createScript = function (url: string) {
  const script = createScript.call(this, url);
  // Null is the only value that will actually remove
  // crossOrigin, everything else gets replaced with `anonymous`
  script.crossOrigin = null;
  return script;
};
